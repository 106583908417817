export const sumNumbers = (numbers?: number[]) => {
  let totalValue = 0;

  numbers &&
    numbers.forEach((number) => {
      totalValue = +totalValue.toFixed(2) + +number.toFixed(2);
    });

  return +totalValue.toFixed(2);
};

/** Calcula a porcentagem de um valor passado  */
export const handleCalcPercent = (value: number, percent: number) => {
  const percentFormat = percent ?? 0 / 100;
  const valueDiscount = (value * percentFormat) / 100;
  const valueDiscountArred = Math.round(valueDiscount * 100) / 100;
  const total = percentFormat ? valueDiscountArred.toFixed(2) : 0;
  return +total;
};

export const getInterestByDate = (
  value: number,
  interest: number,
  daysOfDelay: number,
): number => {
  /** Calculo da porcentagem que deve ser multiplicado pela diferença de dias  */
  const calcPercent = interest / 100 / 30;

  return value * (calcPercent * daysOfDelay);
};
